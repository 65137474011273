.footer-component {
    background: #000000;
}
.logo-app-footer {
    width: 10%;
}
.content-head-footer {
    padding-top: 30px;
    padding-bottom: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.container-app-kokutana {
    padding-left: 7rem;
    padding-right: 7rem;
}

footer .copyright {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
}
.content-end-footer {
    background: #d30606;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Social-media-to-share {
    display: flex;
    justify-content: center;
}
/* .Social-media-to-share a {
    display: flex;
    background: #fd8507;
    height: 39px;
    width: 39px;
    margin: 0 15px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: transform 0.5s;
}
.Social-media-to-share a .share-icon-social-media-item {
    font-size: 20px;
    color: #fff;
    transition: transform 0.5s;
}
.Social-media-to-share a:hover {
    transform: translateY(2px);
    color: #fff;
}
.Social-media-to-share a:hover .share-icon-social-media-item {
    transform: scale(0.9);
}
.Social-media-to-share .facebook:hover {
    background: #3b5998;
}
.Social-media-to-share .instagram:hover {
    background: #7232bd;
}
.Social-media-to-share .linkedin:hover {
    background: #0e76a8;
}
.Social-media-to-share .youtube:hover {
    background: #c4302b;
} */
.footer-title {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.footer-link {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #6d737a;
    gap: 3px;
}
.footer-link a {
    color: #6d737a;
    text-decoration: none;
}
.footer-link a:hover {
    color: #fd8507;
}
.text-newsletter-footer {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.7);
}
.form-control-newsletter {
    background: #ffffff;
    border: 2px solid #f1f1f1;
    border-radius: 45px;
    padding: 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12.1587px;
    line-height: 14px;
    color: #959595;
}
.btn-subscribe-newsletter {
    background: #fd8507;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12.1587px;
    line-height: 14px;
    letter-spacing: 0.065em;
    color: #ffffff;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 11px;
}
.btn-subscribe-newsletter:hover {
    background: #fff;
    box-shadow: 0px 2px 10px 5px #dc72008c;
    color: #fd8507;
    border: 1px solid #fd8507;
}
.content-copyright-footer {
    display: flex;
    gap: 40px;
}
.title-social-media-footer {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-align: center;
}
