body, html {
  font-family: Poppins;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
.breadcrumb-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumb-navlink {
  text-decoration: none;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000;
}
.custom-bread-color {
  color: #000 !important;
}
.breadcrumb-navlink:hover {
  color: #000;
  font-weight: 600;
}
.breadcrumb-current-page {
  margin-bottom: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000;
}
.btn-theme-admin {
  border-radius: 41px;
  background: linear-gradient(140deg, #f44336 -21.28%, #ff9800 56.6%);
  color: #fff !important;
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 45px;
}
.btn-theme-admin:hover {
  color: #f44336 !important;
  border: 1px solid #f44336;
  background: #fff;
}
.btn-add-abonnement-admin {
  color: #ff8d07 !important;
  font-family: "Work Sans";
  font-size: 16.579px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  padding: 12px 45px;
}
.btn-add-abonnement-admin:hover {
  background: linear-gradient(140deg, #f44336 -21.28%, #ff9800 56.6%);
  color: #fff !important;
}

.container-title-page-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.modal-title-custom {
  color: #000;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-label-formule {
  color: #555;
  font-family: "Work Sans";
  font-size: 12.854px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-control-input-formule {
  border-radius: 34.016px;
  border: 0.68px solid rgba(0, 0, 0, 0.22);
  background: #f7f7f7;
  padding: 15px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Work Sans";
  font-size: 10.205px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* css style input radio abonnement */

.content-col-add-abonnement-radio label {
  color: #000;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  -webkit-tap-highlight-color: transparent;
}

.content-col-add-abonnement-radio input[type="radio"] {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  transition: linear 0.8s;
  height: 0;
  width: 0;
  -webkit-tap-highlight-color: transparent;
}

.content-col-add-abonnement-radio input[type="radio"]:after {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  top: -50px;
  left: 40px;
  transition: linear 0.2s;
  cursor: pointer;
}

.content-col-add-abonnement-radio input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background: linear-gradient(140deg, #f44336 -21.28%, #ff9800 56.6%);
  transition: linear 0.2s;
  cursor: pointer;
}

.content-col-add-abonnement-radio input[type="radio"]:checked:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 10px;
  border-left: 4px solid #fff;
  border-bottom: 4px solid #fff;
  transform: rotate(-45deg);
  margin-top: 5px;
  top: -50px;
  left: 45px;
  z-index: 1;
  cursor: pointer;
}

.radio-item-container {
  display: flex;
  padding: 50px 0;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
}

.radio-item {
  display: flex;
  position: relative;
  min-width: 150px;
}

.content-icon {
  transition: linear 0.3s;
}

.content-icon svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.content-col-add-abonnement-radio input[type="radio"]:checked .content-icon {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
}

.content-col-add-abonnement-radio
  input[type="radio"]:checked
  + .content-span-radio-input {
  border: 1px solid #ff9800;
  background-color: #fff;
}

.content-span-radio-input {
  padding: 25px 10px;
  width: 150px;
}
/* css style input radio abonnement */

.content-col-add-abonnement .quill.product-ql-description {
  background-color: #fff;
}
.content-col-add-abonnement .ql-editor.ql-blank {
  min-height: 200px;
}
.content-col-add-abonnement .form-control-input-formule {
  background: #fff !important;
}
.content-btn-submit-form-add-abonnement {
  display: flex;
  justify-content: flex-end;
}
.container-header-formule-detail {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.sub-title-formule-detail {
  color: #000;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.list-formule-content-item {
  color: #111;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12.98px;
}
.img-formule-detail {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.libelle-title-bonnement-detail-item {
  color: #808080;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.container-abonnement-detail-item {
  border-radius: 10px;
  background: #fff;
  display: flex;
  gap: 20px;
  padding: 15px;
  align-items: center;
  width: 100%;
}
.content-img-partner-formule-abonnement {
  width: 60px;
  height: 47px;
}
.img-partner-formule-abonnement {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.title-partner-abonnement-formule-detail-item {
  color: #25282b;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ======== CUSTOM INPUT AVATAR ============ */
.leader-auto-user-avatar-container {
  position: relative;
}
.leader-auto-user-avatar-edit-hover {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 16%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.leader-auto-user-avatar-container:hover .leader-auto-user-avatar-edit-hover {
  opacity: 1 !important;
  cursor: pointer;
}
.input-add-avatar {
  display: none;
}
.input-testt {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pp-profil-user-visiteur {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.workcare-dash-item-page-container {
  position: relative;
}

.leader-auto-user-avatar-edit-hover-deux {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 50%;
}
.leader-auto-user-avatar-container:hover
  .leader-auto-user-avatar-edit-hover-deux {
  opacity: 1 !important;
  cursor: pointer;
}
.leader-auto-user-avatar-edit-hover-deux-profil {
  position: absolute;
  width: 182px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 6px;
}
.leader-auto-user-avatar-container:hover
  .leader-auto-user-avatar-edit-hover-deux-profil {
  opacity: 1 !important;
  cursor: pointer;
}
.edit-avatar-camera-icon {
  font-size: 26px;
}
.medsain-mon-profil-img {
  border-radius: 6px;
  object-fit: cover;
  width: 182px;
  height: 100%;
}
.btn-edit-avatar {
  position: absolute;
  top: 0;
  right: -95px;
  border-radius: 6px;
  border: 1px solid #f48023;
  padding: 8px 15px;
  color: #4f4f4f;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btn-edit-avatar:hover {
  background: #f48023;
  border: 1px solid #f48023;
  color: #fff;
}
.btn-edit-avatar svg {
  color: #200e32;
}
.img-article-item-blog {
  width: 100%;
  height: 469px;
  border-radius: 15px;
}
.text-article-blog {
  color: #000;
  text-align: justify;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
.container-faq-item-admin {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
  width: 100%;
}
.content-header-faq-item-admin {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
}
.content-title-faq-admin-item {
  width: 80%;
}
.title-faq-admin-item {
  color: #000;
  font-family: "Work Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.content-btn-action-faq-admin-item {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
}
.btn-edit-faq-admin,
.btn-edit-faq-admin:hover {
  color: #3a928a;
  padding: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #3a928b38;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-delete-faq-admin,
.btn-delete-faq-admin:hover {
  color: #fa1414;
  padding: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #fa141438;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-faq-admin-item {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 156%;
}
.container-partner-admin-item {
  background: #fff;
  padding: 15px;
  width: 100%;
}
.title-logo-partner-admin-item {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.content-cta-partner-admin-item {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.content-img-partner-admin-item {
  width: 65px;
  height: 48px;
}
img.img-partner-admin-item {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.content-text-option-admin {
  color: #000;
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.status-img-option-admin {
  color: #000;
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
  border-radius: 10px;
  background: rgba(244, 244, 43, 0.3);
  padding: 5px20px;
}
.sm_wrapper__details {
  border: 1px solid #2d374846;
  color: #2d3748;
  border-radius: 12px;
  padding: 20px;
  background-color: #fff;
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
}
.get_info__box {
  border-radius: 32px;
  background: #f7f7f7;
  padding: 15px 30px;
}

.get_info__box .sm_label {
  font-size: 14px;
  font-weight: 700;
  color: #5a5a5a;
}

.get_info__box .sm_value {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}
.custom_top__title {
  color: #00000038;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  letter-spacing: -0.5px;
}
.profil_image__grid {
  display: grid;
  place-items: center;
  position: relative;
}

.profil_image__grid img {
  width: 103px;
  height: 103px;
  object-fit: cover;
  border-radius: 50%;
}
.last_name-and-first_name {
  color: rgba(24, 24, 25, 0.9);
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
}
.bg_content_info_admin {
  background-color: #fff;
  border-radius: 10px;
}
.tabs__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid rgba(51, 51, 51, 0.2);
  padding-left: 0;
  margin-left: 50px;
  margin-right: 50px;
}
.tab_item {
  color: rgba(51, 51, 51, 0.22);
  font-family: "Work Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px;
  text-align: center;
  list-style: none;
  width: 100%;
}
.tab_item__active {
  color: rgba(51, 51, 51, 1);
  border-bottom: 3px solid #ff9800;
}
.admin-table thead {
  border-bottom: 1px solid rgb(247, 247, 251);
  height: 50px;
}
.admin-table table thead tr th {
  text-align: justify;
  color: #808080;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  color: #25282b;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
}
.admin-table .table tbody tr {
  background: #fff;
  border-radius: 0px;
  border-bottom: 10px solid #f4f6f6;
  height: 70px;
}
.admin-table .table {
  border: transparent;
  --bs-table-bg: transparent !important;
}
.img-profil-itemt-table {
  border-radius: 6px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.name-profil-table {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 18px;
  color: #3b4758 !important;
}
.page-item.active .page-link {
  background-color: #e9733b !important;
  border-color: #e9733b !important;
  color: #ffffff !important;
}
button#pageDropDown {
  background-color: #e9733b !important;
  border-color: #e9733b !important;
  color: #ffffff !important;
}

.btn-action-modal-icon {
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
  -webkit-transform: translate(-50%, 18px) scale(0.8);
  -moz-transform: translate(-50%, 18px) scale(0.8);
  -ms-transform: translate(-50%, 18px) scale(0.8);
  -o-transform: translate(-50%, 18px) scale(0.8);
}
.with-tooltip {
  position: relative;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.btn-see-detail-table,
.btn-see-detail-table:hover {
  background: rgba(2, 180, 192, 0.22);
  color: #158a91 !important;
}
.btn-see-detail-table::after {
  color: #158a91 !important;
}
.btn-edit-cell-table,
.btn-edit-cell-table:hover {
  background: rgba(41, 150, 229, 0.22);
  color: #2996e5 !important;
}
.btn-edit-cell-table::after {
  color: #2996e5 !important;
}
.btn-delete-cell-table,
.btn-delete-cell-table:hover {
  background: #fa141415;
  color: #fa1414 !important;
}
.btn-delete-cell-table::after {
  color: #fa1414;
}
.img-profil-formule-table {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.empty-alert-content-body {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding: 50px 15px;
  background: #f4433626;
  border-radius: 10px;
}
.empty-alert-textual-container p {
  margin-bottom: 0;
  color: #b4070a;
  font-weight: 700;
}
.empty-alert-icon {
  font-size: 50px;
  color: #b4070a;
  font-weight: 900;
}
.header-btns-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.logo-container {
  width: 4%;
}
.quotation-btn {
  font-size: 15px;
  background: #2F88E0;
  border-radius: 3px;
  color: #ffffff !important;
  border: 1px solid #2F88E0 !important;
}
.call-me-btn {
  background: #d30606;
  font-size: 15px;
  border-radius: 3px;
  color: #ffffff !important;
  border: 1px solid #d30606 !important;
}
.quotation-btn:hover {
  background: #2F88E040;
  color: #2F88E0 !important;
}
.call-me-btn:hover {
  background: #d3060640;
  color: #d30606 !important;
}
#page-cover {
  background-color: #fce13c;
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2022/05/accord-assistance.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  perspective: 1200px; */
}
/* .page-content-container {
  margin-top: 5%;
} */
.page-cover-title {
  color: #fff;
  font-family: "DM Serif Text", Sans-serif;
  font-size: 90px;
  font-weight: 900;
  line-height: .9em;
  text-align: center;
  position: relative;
  z-index: 999;
}
.page-background-overlay {
  background-color: #000;
  opacity: .4;
  mix-blend-mode: multiply;
  transition: background .3s, border-radius .3s, opacity .3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.page-title-resume {
  color: #fff;
  font-family: Poppins, Sans-serif;
  font-size: 1.5vw;
  line-height: 1.3em;
  z-index: 9999;
  position: relative;
  text-align: center;
}
.apropos-container {
  display: flex;
  align-items: center;
  padding: 50px;
  gap: 30px;
  background: #2F88E080;
}
.apropos-content p {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.6em;
  color: #1a51ea;
}
.quotation-back-btn {
  width: 35%;
  background: #1a51ea;
  padding: 20px;
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: 600;
}
.app-page-container {
  position: relative;
}
#reactif-infos-section {
  margin: 50px 0;
  padding: 30px 0;
}
.reactif-infos-resume-container {
  background: #121212;
  padding: 60px 40px;
}
.reactif-infos-resume p {
  color: #ffffff;
  text-align: justify;
  font-size: 17px;
}
.credo-title {
  color: #1a51ea;
  padding-bottom: 30px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1em;
}
.reactif-infos-slide-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.reactif-infos-slide-item-icon {
  width: 10%;
}
.reactif-infos-slide-item-title.mb-2 {
  font-size: 27px;
  font-weight: 700;
  line-height: 1.1em;
  padding-bottom: 20px;
}
.reactif-infos-slide-item-text p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6em;
  color: #000000;
  margin-bottom: 0;
  text-align: justify;
}
.reactif-infos-row {
  align-items: center;
  background: #d3060610;
}
#fullpage-section {
  padding: 30px;
  background: #d30606;
}
.counter-section-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.counter-item-number {
  font-size: 45px;
  color: #ffffff;
  font-weight: 800;
}
.counter-item-text {
  color: #ffffff;
}
#services-section {
  padding: 40px 0;
}
.service-card-container {
  padding: 50px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
}
.service-card-container.serrurerie {
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-ouverture-porte.jpg");
  background-position: center center;
}
.service-title {
  color: #000;
  font-size: 29px;
  font-weight: 900;
  z-index: 999;
  text-align: center;
}
.service-text-container p {
  color: #000000;
  z-index: 99;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.goto-page-link {
  background: #1a51ea;
  padding: 15px;
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 99;
}
.service-card-overload {
  background-color: #ffffff90;
  opacity: .73;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.service-card-container.porte-blindee {
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2022/05/accord-assistance.jpg");
  background-position: center center;
} 
.service-card-container.porte-garage {
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2022/04/porte-de-garage-basculante.jpg");
  background-position: center center;
}
.services-section-col {
  margin: 20px 0;
}
.service-card-container.autres-services {
  background: #1a51ea;
  z-index: 999;
}
.autres-services .service-title {
  color: #ffffff;
  text-transform: uppercase;
}
.autres-services .service-text-container p {
  color: #ffffff;
}
.autres-services .goto-page-link {
  width: 100%;
  background: #d30606;
  border: 1px solid #ffffff;
}
.partners-section {
  background: transparent !important;
}
.footer-link-ul {
  list-style: none;
  padding-left: 0;
}
.footer-link-ul li a {
  color: #ffffff !important;
  font-size: 14px;
}
.footer-link-ul li a:hover {
  color: #d30606 !important;
}
.app-navbar-brand {
  width: 20%;
}
.app-nav-link.nav-link {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000 !important;
}
.app-nav-link.nav-link:hover {
  color: #d30606 !important;
}
.oh-page-cover-title {
  color: #fff;
  font-family: "DM Serif Text", Sans-serif;
  font-size: 61px;
  font-weight: 900;
  line-height: .9em;
  z-index: 999;
  position: relative;
}
.oh-page-title-resume {
  color: #fff;
  font-family: Poppins, Sans-serif;
  font-size: 1.5vw;
  line-height: 1.3em;
  position: relative;
  z-index: 999;
}
.homepage-cover-resume-container {
  padding: 30px 100px;
}
.service-price-table-content-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.service-price-card-icon-image {
  width: 25%;
}
.service-price-card {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 20px;
  border-radius: 10px;
}
.container.oh-app-page-container {
  padding: 40px 0;
}
.service-card-title {
  color: #000;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2em;
  margin: 20px 0;
}
.service-card-price {
  color: #000;
  font-size: 41px;
  font-weight: 600;
  line-height: 1.4em;
  color: #d30606;
}
.service-textual-container {
  margin: 75px 0;
}
.service-textual-image {
  width: 100%;
}
.service-textual-row {
  align-items: center;
  margin-bottom: 50px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.service-textual-title {
  color: #000;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4em;
  text-transform: uppercase;
  padding: 20px;
}
.service-textual-item {
  color: #000;
  font-family: "DM Sans", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4em;
  padding: 20px;
}
/* .service-textual-content-body {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
} */
.promo-row {
  margin: 40px 0;
  align-items: center;
  background: #d30606;
}
.promo-image {
  width: 100%;
}
.promo-row .promo-image-col {
  padding: 0;
}
.promo-text-title {
  color: #ffffff;
  font-size: 31px;
  font-weight: 900;
  line-height: .9em;
}
.promo-text-second-title {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.3em;
  color: #ffffff;
}
.promo-text-content-body {
  margin: 30px 0;
}
.promo-text-content-body p {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6em;
  color: #ffffff;
}
.service-promo-with-slide-container {
  padding: 30px;
  background-color: #f0f8ff;
}
.service-promo-with-slide-title {
  color: #000;
  font-size: 59px;
  font-weight: 900;
}
.service-promo-with-slide-intro-container p {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6em;
  color: #0e0e0e;
  margin: 30px 0;
}
.service-promo-with-slide-slider-container {
  padding: 30px;
}
.service-details-container {
  margin: 40px 0;
  padding: 30px 0;
}
.service-details-card-container {
  background: #2F88E0;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.service-details-icon-container svg {
  width: 70px;
  height: 70px;
  color: #ffffff !important;
  font-weight: 900;
}
.service-details-icon-container {
  text-align: center;
}
.service-details-card-title {
  color: #fff;
  font-family: "DM Serif Display", Sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2em;
  text-align: center;
  padding: 20px 0;
}
.service-details-icon-container svg path {
  fill: #ffffff;
}
.Service-details-listing li {
  font-family: Quicksand, Sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.service-details-listing-container {
  margin: 20px 0;
}
.Service-details-listing li {
  padding: 5px 0;
}
.why-choose-container {
  padding: 30px 0;
}
.why-choose-title {
  font-size: 31px;
  font-weight: 700;
  padding-bottom: 20px;
}
.why-choose-accordion-container .accordion-button:not(.collapsed) {
  background: #d30606;
  color: #ffffff;
}
.why-choose-accordion-container .accordion-button {
  font-weight: 700;
}
.why-choose-accordion-container .accordion-item {
  border-color: #2F88E080;
}
.global-cta-btn-container {
  position: fixed;
  bottom: 50%;
  right: 3%;
}
.global-cta-btn {
  display: flex;
  font-size: 35px;
  width: 50px;
  height: 50px;
  background: #DD3142;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.app-global-phone-icon {
  color: #ffffff;
}
.global-cta-btn.quotation-btn {
  background: #0d6efd;
}
.phone-link {
  color: #DD3142;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 27px;
  text-transform: uppercase;
  text-decoration: none;
}
.google-badge-image {
  position: fixed;
  bottom: 10%;
  right: 1%;
  width: 7%;
  border: 3px solid #FFF !important;
  border-radius: 50%;
  z-index: 9999;
}
.page-cover-container.volet-roulant {
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2024/04/reparation-volet-roulant.jpg") !important;
}
.service-type-card-container {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 440px;
}
.service-type-image-icon {
  width: 15%;
  margin: 20px 0;
}
.service-type-card-title {
  color: #121212;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2em;
  padding: 10px 0;
}
.service-type-listing li {
  font-size: 14px;
  font-weight: 500;
}
.promo-text-container {
  padding: 20px 0;
}
.promo-text-container .reactif-infos-slide-item-title,
.promo-text-container .reactif-infos-slide-item-text p {
  color: #ffffff !important;
}
.service-volet-image-content-body img {
  width: 100%;
  object-fit: cover;
}
.service-volet-response-row {
  margin: 50px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 20px;
  align-items: center;
  background: #f0f8ff;
}
.service-volet-question-row {
  padding: 40px 20px;
  background: #0d6efd;
}
.servicevolet-question-title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  margin-bottom: 20px;
}
.service-volet-question-text-container p {
  color: #ffffff;
}
.service-volet-response-title {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.3em;
  padding: 20px 0;
}
.service-volet-response-listing li {
  padding: 10px 0;
  color: #000000;
}
.page-cover-container.garage {
  background-image: url("https://accord-assistance.fr/wp-content/uploads/2024/03/porte-de-garage-sectionnelle-scaled.jpg") !important;
}
.service-type-card-body .call-me-btn {
  display: inline-block;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
}
.pas1eurosde-plus{
  text-align: center;
}